import PromoImages from "./PromoImages";
import img1 from "../assets/images/promos/420/promo (1).jpg";
import img2 from "../assets/images/promos/420/promo (2).jpg";
import img3 from "../assets/images/promos/420/promo (3).jpg";
import img4 from "../assets/images/promos/420/promo (4).jpg";
import img5 from "../assets/images/promos/420/promo (5).jpg";
import img6 from "../assets/images/promos/420/promo (6).jpg";
import flyer from "../assets/images/promos/420/flyer.jpg";

export default function Promo420() {
  const images = [img1, img2, img3, img4, img5, img6];
  const name = "420";

  return (
    <>
      <div className="row justify-content-center text-center mt-5">
        <img src={flyer} alt="promo flyer" style={{ maxWidth: "800px" }} />
        <h3 className="mt-5">Details:</h3>
        <ul style={{ listStyleType: "none" }} className="fs-5 mb-5">
          <li>Free sunglasses with each tattoo</li>
          <li>
            Price ranges: $150, $200, $420 (based off size, placement,
            outline/shading/color, etc)
          </li>
          <li>Giveaways all weekend long</li>
          <li>Check our Instagram to win a $420 free tattoo!</li>
        </ul>
      </div>
      <PromoImages images={images} name={name} />
    </>
  );
}
