export default function PromoImages({ images, name }) {
  return (
    <div className="row justify-content-center">
      {images.map((item) => {
        return (
          <div className="col-6 col-md-3 col-lg-2 mb-20" key={item}>
            <div
              className="imageThumbnail"
              data-fancybox={name}
              data-src={item}
            >
              <img
                className="gridPic border border-light border-opacity-50"
                src={item}
                alt=""
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
