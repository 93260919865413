import { useRef } from "react";
import turtles from "../assets/images/turtles.png";
import { artistsList, shopInfo } from "../components";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { FaRegWindowClose } from "react-icons/fa";
import { Helmet } from "react-helmet";

function Contact() {
  const location = useLocation();
  const artistFromBio = location.state?.artist;
  const form = useRef();
  const image1 = useRef();
  const image2 = useRef();
  const image3 = useRef();

  function removeImage1() {
    image1.current.value = "";
  }
  function removeImage2() {
    image2.current.value = "";
  }
  function removeImage3() {
    image3.current.value = "";
  }

  const [waitMessage, setWaitMessage] = useState("");

  const [apptFname, setApptFname] = useState("");
  const [apptLname, setApptLname] = useState("");
  const [apptEmail, setApptEmail] = useState("");
  const [apptPhone, setApptPhone] = useState("");
  const [optInSMS, setOptInSMS] = useState(false);
  const [apptArtist, setApptArtist] = useState(artistFromBio);
  const [apptDate, setApptDate] = useState("");
  const [apptTime, setApptTime] = useState("");
  const [apptMessage, setApptMessage] = useState("");

  const [errorFname, setErrorFname] = useState("");
  const [errorLname, setErrorLname] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorOptIn, setErrorOptIn] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!apptFname) {
      setErrorFname("Please enter your first name");
    } else setErrorFname("");
    if (!apptLname) {
      setErrorLname("Please enter your last name");
    } else setErrorLname("");
    if (!apptEmail) {
      setErrorEmail("Please enter your email address");
    } else setErrorEmail("");
    if (!apptPhone) {
      setErrorPhone("Please enter your phone number");
    } else setErrorPhone("");
    if (optInSMS === false) {
      setErrorOptIn("Opt-in is required to book an appointment");
    } else setErrorOptIn("");
    if (!apptMessage) {
      setErrorMessage("Please describe your desired tattoo");
    } else setErrorMessage("");

    if (
      apptFname &&
      apptLname &&
      apptEmail &&
      apptPhone &&
      optInSMS === true &&
      apptMessage
    ) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_70dkx5g",
          "template_skgm6qz",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setApptFname("");
            setApptLname("");
            setApptEmail("");
            setApptPhone("");
            setOptInSMS(false);
            setApptArtist("");
            setApptDate("");
            setApptTime("");
            setApptMessage("");
            setWaitMessage("Your appointment request has been submitted.");
            removeImage1();
            removeImage2();
            removeImage3();
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage("Upload is too large. 500Kb total for 3 images.");
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };

  const mapURL = shopInfo.map;
  artistsList.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo & Coffee Bar Kalakaua | Book</title>
      </Helmet>
      <section id="contact" className="contact_area pt-60">
        <div className="contact_form">
          <div className="container">
            <div className="row justify-content-center">
              <div className="section_title text-center pb-30">
                <h4 className="title">Book an Appointment</h4>
                <img className="turtles" alt="turtle pic" src={turtles} />
              </div>
            </div>
            <form ref={form} id="contact-form" onSubmit={onSubmit}>
              <div className="row pb-30">
                <div className="col-md-6">
                  <div className="single_form mt-30">
                    <input
                      style={{ display: "none" }}
                      name="shopLocation"
                      defaultValue={shopInfo.contact}
                    />
                    <input
                      value={apptFname}
                      name="fname"
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => {
                        setApptFname(e.target.value);
                        setErrorFname("");
                      }}
                    />
                    {errorFname ? (
                      <p className="contactError">{errorFname}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_form mt-30">
                    <input
                      value={apptLname}
                      name="lname"
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => {
                        setApptLname(e.target.value);
                        setErrorLname("");
                      }}
                    />
                    {errorLname ? (
                      <p className="contactError">{errorLname}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_form mt-30">
                    <input
                      value={apptEmail}
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => {
                        setApptEmail(e.target.value);
                        setErrorEmail("");
                      }}
                    />
                    {errorEmail ? (
                      <p className="contactError">{errorEmail}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_form mt-30">
                    <input
                      value={apptPhone}
                      name="phone"
                      type="tel"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setApptPhone(e.target.value);
                        setErrorPhone("");
                      }}
                    />
                    {errorPhone ? (
                      <p className="contactError">{errorPhone}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single_form checkbox-container">
                    <label className="checkbox-label">
                      <input
                        className="checkbox-box"
                        name="checkbox"
                        type="checkbox"
                        checked={optInSMS}
                        onChange={(e) => {
                          setOptInSMS(e.target.checked);
                          setErrorOptIn("");
                        }}
                      />
                      Opt-in to receive appointment updates
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="mb-3">
                    By checking this box, I agree to receive SMS messages about
                    booking inquiries from Sacred Art Hawaii at the phone number
                    provided above. The SMS frequency may vary. Data rates may
                    apply. Text HELP to 1-808-892-3335 for assistance. Reply
                    STOP to opt out of receiving SMS messages.
                  </p>
                  <p>
                    Read our{" "}
                    <Link to="/terms" className="text-decoration-underline">
                      Terms of Service
                    </Link>{" "}
                    for SMS Communication
                  </p>
                  {errorOptIn ? (
                    <p className="contactError">{errorOptIn}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <div className="single_form mt-30">
                    <select
                      value={apptArtist}
                      name="artist"
                      onChange={(e) => {
                        setApptArtist(e.target.value);
                      }}
                      className="form-select"
                    >
                      <option>Requested Artist?</option>
                      <option value="Not Sure">Not Sure</option>
                      {artistsList.map((artist) => {
                        return (
                          <option key={artist.url} value={artist.name}>
                            {artist.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single_form mt-30">
                    <input
                      value={apptDate}
                      name="date"
                      type="date"
                      onChange={(e) => {
                        setApptDate(e.target.value);
                      }}
                    />
                    <label htmlFor="date">
                      Requested Appointment Date. If your date is unavailable,
                      we will let you know and schedule you as soon as possible.
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single_form mt-30">
                    <select
                      value={apptTime}
                      name="time"
                      onChange={(e) => {
                        setApptTime(e.target.value);
                      }}
                      className="form-select"
                    >
                      <option>What time are you available?</option>
                      <option value="No Preference">No Preference</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single_form mt-30">
                    <label htmlFor="message">
                      Describe what you want to get tattooed and where. Please
                      include as much detail as possible.
                    </label>
                    <textarea
                      value={apptMessage}
                      name="message"
                      onChange={(e) => {
                        setApptMessage(e.target.value);
                        setErrorMessage("");
                      }}
                    ></textarea>
                    {errorMessage ? (
                      <p className="contactError">{errorMessage}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="container">
                  <p className="pt-10" style={{ paddingBottom: "5px" }}>
                    Attach up to 3 images
                  </p>
                  <div className="row" style={{ paddingBottom: "5px" }}>
                    <div className="attachmentField col-10 col-md-6 col-lg-4">
                      <input
                        ref={image1}
                        type="file"
                        name="attachment1"
                        id="attachment1"
                      />
                    </div>
                    <div className="col-2 col-md-6 col-lg-8">
                      <FaRegWindowClose size="24px" onClick={removeImage1} />
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "5px" }}>
                    <div className="attachmentField col-10 col-md-6 col-lg-4">
                      <input
                        ref={image2}
                        type="file"
                        name="attachment2"
                        id="attachment2"
                      />
                    </div>
                    <div className="col-2 col-md-6 col-lg-8">
                      <FaRegWindowClose size="24px" onClick={removeImage2} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="attachmentField col-10 col-md-6 col-lg-4">
                      <input
                        ref={image3}
                        type="file"
                        name="attachment3"
                        id="attachment3"
                      />
                    </div>
                    <div className="col-2 col-md-6 col-lg-8">
                      <FaRegWindowClose size="24px" onClick={removeImage3} />
                    </div>
                  </div>
                </div>
                <div className="form-message mt-30">
                  <ul>
                    <li>Please bring your photo ID</li>
                    <li>
                      You must be 18+ years of age to get tattooed, unless you
                      have parental consent. Both must have photo
                      identification.
                    </li>
                    <li>
                      Wear comfortable clothes that are accessible to the area
                      you are getting tattooed.
                    </li>
                    <li>
                      Please make your artist aware of any medical condition you
                      might have so your tattoo experience remains safe.
                    </li>
                    <li>
                      Our tattoo artists invest a lot of time, energy, and
                      resources in preparing for your appointment. To secure
                      your time slot, we require a non-refundable deposit. While
                      we understand that unexpected events can happen,
                      last-minute cancellations can significantly impact our
                      artists' livelihoods. We will work with you to reschedule
                      your appointment whenever possible. If you have any
                      questions or concerns about our deposit policy, please
                      reach out to us. Thank you for your understanding and
                      respect for our artists' hard work. We can't wait to work
                      with you to create your perfect tattoo!
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12">
                  <div className="single_form text-center mt-30">
                    {waitMessage ? (
                      <p className="mb-10 submitMessage">
                        <em>{waitMessage}</em>
                      </p>
                    ) : (
                      ""
                    )}
                    <button type="submit" className="main-btn">
                      BOOK NOW
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="shortMerch">
              <p className="pb-10">
                If you've already filled out our contact form, you can leave a
                deposit for your appointment
              </p>
              <a
                href="https://form.jotform.com/223641666882162"
                target="_blank"
                rel="noreferrer noopener"
              >
                <button className="main-btn">LEAVE A DEPOSIT</button>
              </a>
            </div>
          </div>
        </div>
        <div className="pt-60 contact_map">
          <div className="gmap_canvas">
            <iframe
              title="googleMap"
              src={mapURL}
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
