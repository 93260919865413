const shopInfo = {
  facebook: "https://www.facebook.com/sacredarttattoohi",
  instagram: "https://www.instagram.com/sacredarttattoo_honolulu",
  yelp: "https://www.yelp.com/biz/sacred-art-tattoo-and-coffee-bar-urban-honolulu-2",
  address: "1925 Kalakaua Ave, Unit A, Honolulu HI 96813",
  phone: "(808) 460-7311",
  title: "Sacred Art Tattoo & Coffee Bar Honolulu",
  about:
    "Sacred Art Honolulu is the one and only tattoo shop and coffee bar in Waikiki. Proudly serving the community since 1992, we bring together world-class tattooing, local art, and locally roasted coffee in a unique, vibrant space.",
  about2:
    "Our shop provides a clean, laid-back environment where creativity thrives, allowing our talented artists to connect with guests and design one-of-a-kind tattoos in a wide range of styles. At Sacred Art, we’re all about delivering an unforgettable experience—combining artistry, connection, and a great cup of coffee.",
  about3: "",
  email: "sales@sacredarthawaii.com",
  hours: "Cafe: 7 AM - 4 PM, Tattoo: 10 AM - Midnight",
  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7435.2537765947!2d-157.83350200033402!3d21.286231880711323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c006d0847157a19%3A0x28c27062b5e414e1!2sSacred%20Art%20Tattoo%20and%20Coffee%20Bar!5e0!3m2!1sen!2sus!4v1679367484176!5m2!1sen!2sus",
  contact: "Kalakaua",
};

export default shopInfo;
