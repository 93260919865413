import turtles from "../assets/images/turtles.png";

function EmailUs() {
  return (
    <div>
      <section id="about" className="d-flex about_area mt-60">
        <div className="container">
          <div className="row justify-content-center mb-50">
            <div className="col-lg-6">
              <div className="section_title text-center pb-10">
                <h4 className="title">Hey, got a question?</h4>
                <img className="turtles" src={turtles} alt="turtles" />
                <a className="d-block" href="mailto:sales@sacredarthawaii.com">
                  <h2 className="mt-4">
                    We love questions! <u>Email us</u>.
                  </h2>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EmailUs;
