import turtles from "../assets/images/turtles.png";
import Button from "react-bootstrap/Button";
import { FaDownload } from "react-icons/fa";
import { Helmet } from "react-helmet";

function Faq() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo & Coffee Bar Kalakaua | FAQ</title>
      </Helmet>
      <div className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section_title text-center pb-30">
              <h4 className="title">FAQ</h4>
              <img className="turtles" alt="turtles" src={turtles} />
            </div>
          </div>
        </div>
        <div className="servicesBorders">
          <div className="pb-30" style={{ textAlign: "center" }}>
            <a href="SATW - Aftercare Instructions.pdf">
              <Button variant="light">
                <FaDownload className="mr-10" />
                Download Aftercare Instructions
              </Button>
            </a>
          </div>
          <h4 className="mb-10">1. HOW MUCH DO TATTOOS COST?</h4>
          <p className="mb-30">
            There is a $100 shop minimum on all tattoos, but the cost will vary
            depending on location of the tattoo, how long it takes, how complex
            the design is, and other factors that go into the amount of work
            your tattoo requires.
          </p>
          <h4 className="mb-10">2. WHAT'S YOUR DEPOSIT POLICY?</h4>
          <p className="mb-30">
            We understand that sometimes plans change, but we wanted to remind
            our clients that deposits are non-refundable. Our artists take their
            craft seriously and spend significant time preparing for your
            appointment. Deposits are required to secure your spot and
            compensate our artists for their time and effort. By making a
            deposit, you're showing your commitment to your appointment and
            allowing us to prepare and plan accordingly. We hope you understand
            our policy and appreciate your cooperation. If you have any
            questions or concerns, please don't hesitate to reach out to us.
            We're always happy to chat and work with you to make your tattoo
            experience the best it can be.
          </p>
          <h4 className="mb-10">3. WHAT'S THE PRICE FOR PIERCINGS?</h4>
          <p className="mb-30">
            Depending on difficulty of the piercing all of our standard
            piercings are $60 and go up from there.
          </p>
          <h4 className="mb-10">4. IF I AM SUNBURNED CAN I GET TATTOOED?</h4>
          <p className="mb-30">
            The artist will have to take a look and see if it too bad to get
            tattooed. PLEASE SUNSCREEN THE AREA YOUR GETTING WORKED ON PRIOR TO
            TATTOO APPOINTMENT!
          </p>
          <h4 className="mb-10">5. DO YOU NEED AN APPOINTMENT?</h4>
          <p className="mb-30">
            We encourage appointments if you have a preferred time or date as
            walk-ins are first come, first served and will depend on
            availability. If you are leaving Hawaii soon, we recommend coming in
            and making an appointment to secure a spot. We also strongly suggest
            you schedule your appointment the day before you leave because you
            will to need stay out of the sun and water during the healing
            process.
          </p>
          <h4 className="mb-10">6. WHAT IS THE AFTERCARE PROCESS?</h4>
          <p className="mb-30">
            Leave the bandage on until you get home or back to your hotel. Wash
            thoroughly in as warm of water as you can comfortably stand with
            antibacterial soap, one with no dyes or perfumes such as dial. If
            your tattoo feels slimy, that's normal - it's just plasma. Wash
            until the slimy feeling is gone. Pat, do not rub, with a clean, dry
            towel. Do not use Neosporin or A&D. Wait 24 to 48 hours before
            putting any aftercare product on your tattoo. If you prefer, wash
            your new tattoo 2-3 times a day and after clean put a thin layer of
            perfume and dye-free lotion (such as Lubriderm or Aquaphor). Don't
            let the direct pressure from the shower hit your tattoo. Avoid hot
            tubs and swimming until the tattoo is completely healed (about 2-3
            weeks). As your new tattoo heals, you may notice peeling or some
            minor scabbing. If this happens - don't pick or scratch! Sun is the
            number one factor in the deterioration of a tattoo over time. Use
            30SPF year-round on your tattoo.
          </p>
          <h4 className="mb-10">7. DOES THE SHOP SANITIZE THE EQUIPMENT?</h4>
          <p className="mb-30">
            Yes, we take cleanliness very seriously. All our equipment is
            hospital-grade sterile and our artist are licensed by the state of
            Hawaii. The shop undergoes a yearly state inspection and always
            passes with an A+ grade.
          </p>
          <h4 className="mb-10">8. HOW OLD DO YOU HAVE TO BE?</h4>
          <p className="mb-30">
            18 years of age unless you have parental consent. Both must have
            photo identification.
          </p>
          <h4 className="mb-10">
            9. WHAT SHOULD I EXPECT ON THE DAY OF MY APPOINTMENT?
          </h4>
          <p className="mb-30">
            On the day of your appointment, bring a photo ID, wear comfortable
            clothes that are accessible to the area you're getting tattooed, and
            eat a solid meal before coming in. Please make your artist aware of
            any medical condition you might have so your tattoo experience
            remains safe. Please call the shop if you are going to be late or
            need to reschedule.
          </p>
          <h4 className="mb-10">WELCOME TO THE FAMILY!</h4>
        </div>
      </div>
    </>
  );
}

export default Faq;
