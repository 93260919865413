import Carousel from "react-bootstrap/Carousel";
import slide1 from "../assets/images/slides/kalakaua 1.jpg";
import slide2 from "../assets/images/slides/kalakaua 2.jpg";
import slide3 from "../assets/images/slides/kalakaua 3.jpg";
import slide4 from "../assets/images/slides/kalakaua 4.jpg";
import slide5 from "../assets/images/slides/kalakaua 5.jpg";
import slide6 from "../assets/images/slides/kalakaua 6.jpg";
import slide7 from "../assets/images/slides/kalakaua 7.jpg";

function HeroImage() {
  return (
    <Carousel indicators={true} interval={5000} fade pause="false">
      <Carousel.Item>
        <img src={slide1} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide2} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide3} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide4} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide5} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide6} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide7} alt="kalakaua" className="heroImage w-100" />
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroImage;
