import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Privacy Policy</title>
      </Helmet>
      <div className="container mt-60 mb-60 text-start">
        <h1 className="text-center mb-5">Privacy Policy</h1>
        <p className="fw-bold fs-5 mb-2">Last Updated:12/01/24</p>
        <p className="mb-4">
          Sacred Art Tattoo Hawaii (“we,” “us,” “our”) values your privacy and
          is committed to protecting your personal information. This Privacy
          Policy explains how we collect, use, and share your information when
          you interact with our business, including through our website,
          appointment scheduling, and feedback collection.
        </p>
        <p className="fw-bold fs-5 mb-2">Information We Collect</p>
        <p className="mb-1">
          We may collect the following types of information:
        </p>
        <ul className="mb-4">
          <li>
            Personal Information: Your name, phone number, email address, and
            other details provided when scheduling appointments or leaving
            feedback.
          </li>
          <li>
            Usage Information: Data about how you interact with our website,
            such as your IP address, browser type, and pages viewed.
          </li>
        </ul>
        <p className="fw-bold fs-5 mb-2">How We Use Your Information</p>
        <p className="mb-1">
          We collect and use your information for the following purposes:
        </p>
        <ol className="mb-4">
          <li>To schedule appointments at our locations.</li>
          <li>
            To collect feedback about your visit and improve our services.
          </li>
          <li>
            To send SMS messages related to appointment confirmations, updates,
            or feedback requests.
          </li>
          <li>To respond to your inquiries and provide customer support.</li>
        </ol>
        <p className="fw-bold fs-5 mb-2">Sharing Your Information</p>
        <p className="mb-1">
          We do not share your personal information with third parties, except:
        </p>
        <ul className="mb-4">
          <li>If required by law or legal process.</li>
          <li>
            To protect the rights, property, or safety of our business,
            customers, or others.
          </li>
        </ul>
        <p className="fw-bold fs-5 mb-2">Your Choices</p>
        <ol className="mb-4">
          <li>
            SMS Opt-Out: You can opt out of receiving SMS messages by replying
            STOP to any message.
          </li>
          <li>
            Access and Update: Contact us to update your information or request
            its removal.
          </li>
        </ol>
        <p className="fw-bold fs-5 mb-2">Cookies and Tracking</p>
        <p className="mb-4">
          Our website may use cookies to enhance your experience and analyze
          site usage. You can adjust your browser settings to decline cookies if
          preferred.
        </p>
        <p className="fw-bold fs-5 mb-2">Security</p>
        <p className="mb-4">
          We implement reasonable safeguards to protect your information, but no
          system is completely secure. Please contact us immediately if you
          suspect any unauthorized access to your information.
        </p>
        <p className="fw-bold fs-5 mb-2">Children’s Privacy</p>
        <p className="mb-4">
          Our services are not directed to individuals under 18, and we do not
          knowingly collect personal information from children.
        </p>
        <p className="fw-bold fs-5 mb-2">Changes to This Policy</p>
        <p className="mb-4">
          We may update this Privacy Policy periodically. Any changes will be
          posted on this page with an updated effective date.
        </p>
        <p className="fw-bold fs-5 mb-2">Contact Us</p>
        <p className="mb-4">
          If you have questions about this Privacy Policy or how your
          information is used, please contact us at:
          <br />
          <strong>Email: </strong>
          <a href="mailto:sales@sacredarthawaii.com">
            sales@sacredarthawaii.com
          </a>
          <br />
          <strong>Phone: </strong>
          <a href="tel:8088923335">808-892-3335</a>
          <br />
          <strong>Address: </strong>1925 Kalakaua Ave, Suite A, Honolulu, Hawaii
          96815
        </p>
      </div>
    </>
  );
};

export default Privacy;
