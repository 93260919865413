import React, { useState, useEffect, useRef } from "react";

const LazyLoadImage = ({ src, alt, className }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const currentImgRef = imgRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (observer && currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isIntersecting ? src : null}
      alt={alt}
      className={className}
    />
  );
};

export default LazyLoadImage;
