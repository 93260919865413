import getImages from "./GetImages";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";

function FancyGallery(props) {
  <Fancybox />;
  const randomImages = getImages();
  const filterImagesOld = randomImages.filter((image) => {
    return image.includes(props.files + "-old");
  });
  const filterImagesNew = randomImages.filter((image) => {
    return image.includes(props.files + "-new");
  });

  return (
    <div id="imageGallery" className="row">
      {filterImagesNew.map((item) => {
        return (
          <div
            className="col-6 col-md-3 col-lg-2 mb-20"
            key={`${props.name}.${item}`}
          >
            <div
              className="imageThumbnail"
              data-fancybox={props.name}
              data-src={item}
            >
              <img className="gridPic" src={item} alt="" />
            </div>
          </div>
        );
      })}
      {filterImagesOld.map((item) => {
        return (
          <div
            className="col-6 col-md-3 col-lg-2 mb-20"
            key={`${props.name}.${item}`}
          >
            <div
              className="imageThumbnail"
              data-fancybox={props.name}
              data-src={item}
            >
              <img className="gridPic" src={item} alt="" />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FancyGallery;
