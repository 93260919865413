import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function LocationCard({
  title,
  pic,
  description,
  link,
  border,
}) {
  const theme = createTheme({
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "10px !important",
            paddingLeft: "14px !important",
            textAlign: "center",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow:
              "0px 3px 5px -1px rgba(255,255,255,0.2),0px 5px 8px 0px rgba(255,255,255,0.14),0px 1px 14px 0px rgba(255,255,255,0.12)",
            backgroundImage: "none",
            margin: "0 auto",
          },
        },
      },
    },
    palette: {
      mode: "dark",
    },
  });
  const theme2 = createTheme({
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "10px !important",
            paddingLeft: "14px !important",
            textAlign: "center",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "0 0 8px 5px rgba(255,255,0, .8)",
            backgroundImage: "none",
            margin: "0 auto",
          },
        },
      },
    },
    palette: {
      mode: "dark",
    },
  });

  return (
    <div className="col-sm-6 col-md-4 locationCard">
      {link ? (
        <ThemeProvider theme={theme}>
          {border ? (
            <Card sx={{ maxWidth: 345, borderRadius: "70px" }}>
              <CardActionArea>
                <a href={link} target="_blank" rel="noreferrer noopener">
                  <CardMedia
                    component="img"
                    height="200"
                    image={pic}
                    alt="location pic"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="div"
                      color="white"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" color="#ddd">
                      {description}
                    </Typography>
                  </CardContent>
                </a>
              </CardActionArea>
            </Card>
          ) : (
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <a href={link} target="_blank" rel="noreferrer noopener">
                  <CardMedia
                    component="img"
                    height="200"
                    image={pic}
                    alt="location pic"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="div"
                      color="white"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" color="#ddd">
                      {description}
                    </Typography>
                  </CardContent>
                </a>
              </CardActionArea>
            </Card>
          )}
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme2}>
          {border ? (
            <Card sx={{ maxWidth: 345, borderRadius: "70px" }}>
              <CardActionArea>
                <a href="#0">
                  <CardMedia
                    component="img"
                    height="200"
                    image={pic}
                    alt="location pic"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="div"
                      color="white"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" color="#ddd">
                      {description}
                    </Typography>
                  </CardContent>
                </a>
              </CardActionArea>
            </Card>
          ) : (
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <a href="#0">
                  <CardMedia
                    component="img"
                    height="200"
                    image={pic}
                    alt="location pic"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="div"
                      color="white"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" color="#ddd">
                      {description}
                    </Typography>
                  </CardContent>
                </a>
              </CardActionArea>
            </Card>
          )}
        </ThemeProvider>
      )}
    </div>
  );
}
